import React from "react";
import { graphql } from "gatsby";
import Nav from "../components/Nav";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	margin-top: 4rem;
`;

const Center = styled.div`
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	padding-left: 16px;
	padding-right: 16px;
`;

const Title = styled.h1`
	font-family: "fieldwork";
	font-weight: 900;
	font-style: normal;
	font-size: 3.4rem;
	line-height: 0.94;
	color: #1a1818;
	padding-bottom: 1.6rem;
`;

const ParaGraph = styled.div`
	color: #1a1818;
	p {
		font-family: "fieldwork";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
		margin-bottom: 1rem;
	}
	strong {
		font-weight: 700;
	}
	ol {
		margin-bottom: 1rem;
	}
	li {
		font-family: "fieldwork";
		font-weight: 300;
		font-style: normal;
	}
	a {
		text-decoration: underline;
	}
`;

export default ({ data: { page } }) => (
	<div>
		<Nav />
		<Container>
			<Center>
				<Title>{page.title}</Title>
				<ParaGraph dangerouslySetInnerHTML={{ __html: page.content }} />
			</Center>
		</Container>
	</div>
);

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "voi-kaak-404" }) {
			content
			title
			seo {
				metaDesc
				title
			}
		}
	}
`;
